class UserAPI {
  API;

  constructor(API) {
    this.API = API;
  }

  accounts({ success, failure, complete }) {
    this.API.sendRequest("/user/accounts", {
      type: "GET",
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  email_valid({ email, success, failure, complete }) {
    this.API.sendRequest("/user/email_valid", {
      type: "POST",
      data: JSON.stringify({ email }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  register({
    full_name,
    organization,
    email,
    password,
    with_string_identifier,
    education,
    plan_code,
    token_id,
    three_d_token,
    success,
    failure,
    complete
  }) {
    this.API.sendRequest("/users/sign_up", {
      type: "POST",
      data: JSON.stringify({
        full_name,
        organization,
        email,
        password,
        plan_code,
        with_string_identifier,
        education,
        token: token_id,
        three_d_token
      }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  change_name({ full_name, success, failure, complete }) {
    this.API.sendRequest("/user/change_name", {
      type: "POST",
      data: JSON.stringify({ full_name }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  change_email({ payload, success, failure, complete }) {
    this.API.sendRequest("/user/change_email", {
      type: "POST",
      data: JSON.stringify(payload),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  change_password({ payload, success, failure, complete }) {
    this.API.sendRequest("/user/change_password", {
      type: "POST",
      data: JSON.stringify(payload),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  send_reset_password_instructions({ email, success, failure, complete }) {
    this.API.sendRequest("/users/password.json", {
      type: "POST",
      data: JSON.stringify({ user: { email } }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  get_updates({ email, success, failure, complete }) {
    this.API.sendRequest("/user/get_updates", {
      type: "POST",
      data: JSON.stringify({ email }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  // CAUTION: Removes user from NoteApp, including team memberships,
  // and closing accounts where the logged in user is the sole administrator.
  lights_out({ reason, success, failure, complete }) {
    this.API.sendRequest("/user/lights_out", {
      type: "DELETE",
      data: JSON.stringify({ reason }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }
}

export { UserAPI };
